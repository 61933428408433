import { CarResponse } from 'driverama-core/api/driverama/stock/stockCarDetail'
import { Hit } from 'react-instantsearch-core'
import { OpportunityCar } from 'sections/buyingWizard/priceRange/BuyingWizardPriceRange.utils'
import { AlgoliaStockCarResponse } from 'sections/serp/Serp.types'

export type CarSearchResponse = Array<Hit<AlgoliaStockCarResponse>>

export const getCarDataLayerArray = (
  cars: CarSearchResponse
): CarDataLayer[] => {
  return [
    ...cars.map(car => ({
      car_name: `${car?.makeId} | ${car?.modelId} (${car?.yearOfMake})`,
      car_id: '',
      car_price: car?.prices?.[0]?.fullPriceNoVatEur || undefined,
      car_mileage: car?.actualMileageKm || undefined,
      car_estimated_delivery: undefined,
      stock_no: car?.stockNo || ''
    }))
  ]
}

export const getCarDataLayer = (car: CarResponse | undefined): CarDataLayer => {
  return {
    car_name: `${car?.makeId} | ${car?.modelId} (${car?.yearOfMake})`,
    car_id: `${car?.id}`,
    car_price: car?.prices?.fullPriceNoVatEur || undefined,
    car_mileage: car?.actualMileageKm || undefined,
    car_estimated_delivery: undefined,
    stock_no: car?.stockNo || ''
  }
}

export const getOpportunityCarDataLayer = (
  car: OpportunityCar | undefined
) => ({
  car_make: car?.makeId,
  car_model: car?.modelId,
  car_engine: car?.engineId,
  car_made: car?.made,
  car_body: car?.bodyId,
  car_drive_type: car?.driveId,
  car_transmission: car?.transmissionId,
  car_fuel_type: car?.fuelTypeId,
  car_mileage: car?.mileageKm,
  car_color: `${car?.colorId} ${car?.metallicColor ? '(metallic)' : ''}`,
  car_features: car?.features.join(', '),
  car_accident_severity: car?.accidentSeverity,
  car_interior_condition: car?.conditions.find(
    condition => condition.type === 'INTERIOR'
  )?.rating,
  car_exterior_condition: car?.conditions.find(
    condition => condition.type === 'EXTERIOR'
  )?.rating,
  car_mechanical_condition: car?.conditions.find(
    condition => condition.type === 'MECHANICAL_PARTS'
  )?.rating
})
