import { LoanCalculatorFormValues } from 'components/financing/LoanCalculator.utils'
import { CarResponse } from 'driverama-core/api/driverama/stock/stockCarDetail'
import { OpportunityCar } from 'sections/buyingWizard/priceRange/BuyingWizardPriceRange.utils'
import { InquiryFormValues } from 'sections/financing/inquiry/FinancingInquiry.utils'
import { Keyword } from 'sections/serp/SerpKeywords'
import {
  getCarDataLayer,
  getCarDataLayerArray,
  getOpportunityCarDataLayer
} from 'utils/gtm/dataLayer/carDataLayer'
import { getFormDataLayer } from 'utils/gtm/dataLayer/formDataLayer'
import {
  getSearchDataLayer,
  SearchResult
} from 'utils/gtm/dataLayer/searchDataLayer'
import {
  createLeadId,
  getUserDataLayer
} from 'utils/gtm/dataLayer/userDataLayer'
import { logGtmEvent } from 'utils/gtm/event/logGtmEvent'
import {
  getFinancingDataLayer,
  getPageDataLayer
} from './dataLayer/pageDataLayer'

export const logGtmGoToBranchGoogleMap = (branchId: string) => {
  return logGtmEvent({
    eventName: 'click',
    parameters: {
      click_data: { type: 'map', branch_id: branchId }
    }
  })
}

export const logGtmPhoneClick = (phoneNumber: string) => {
  return logGtmEvent({
    eventName: 'click',
    parameters: {
      click_data: { type: 'phone', phone_number: phoneNumber }
    }
  })
}

export const logGtmCtaClick = (cta_name: string) => {
  return logGtmEvent({
    eventName: 'click',
    parameters: {
      click_data: { type: 'cta', cta_name }
    }
  })
}

export const logGtmUserLogout = () => {
  return logGtmEvent({
    eventName: 'logout',
    parameters: {
      form_data: getFormDataLayer('logout')
    }
  })
}

export const logGtmFormSubmit = (
  formType: GTMFormType,
  form_data?: Partial<FormDataLayer>
) => {
  const lead_id = createLeadId()

  return logGtmEvent({
    eventName: 'generate_lead',
    parameters: {
      user_data: { lead_id },
      form_data: { ...getFormDataLayer(formType), ...form_data }
    }
  })
}

export const logGtmUserLogin = (form_data: Omit<FormDataLayer, 'type'>) => {
  return logGtmEvent({
    eventName: 'login',
    parameters: {
      form_data: { ...getFormDataLayer('login'), ...form_data }
    }
  })
}

export const logGtmUserRegistration = () => {
  return logGtmEvent({
    eventName: 'registration',
    parameters: {
      form_data: getFormDataLayer('sign_up')
    }
  })
}

export const logGtmSearch = (
  searchResult: SearchResult,
  keywords: string | Keyword[]
) => {
  return logGtmEvent({
    eventName: 'search',
    parameters: {
      car_data: getCarDataLayerArray(searchResult.hits),
      search_data: getSearchDataLayer(searchResult, keywords)
    }
  })
}

export const logGtmAppointmentOpen = (
  order_id: string,
  opportunity_id?: string
) => {
  return logGtmEvent({
    eventName: 'appointment_booked',
    parameters: {
      transaction_data: { order_id, opportunity_id }
    }
  })
}
export const logGtmAppointmentBooked = (
  order_id: string,
  opportunity_id?: string
) => {
  return logGtmEvent({
    eventName: 'appointment_booked',
    parameters: {
      transaction_data: { order_id, opportunity_id }
    }
  })
}

export const logGtmCalculatorFinancingSubmit = (
  car: CarResponse,
  locale: string,
  calculationId: string,
  loanData: LoanCalculatorFormValues
) => {
  return logGtmEvent({
    eventName: 'calculator_request',
    parameters: {
      car_data: getCarDataLayer(car),
      page_data: getPageDataLayer(locale, { page_type: 'product' }),
      form_data: {
        type: 'calculator'
      },
      financing_data: {
        calculation_id: calculationId,
        ...getFinancingDataLayer(loanData)
      }
    }
  })
}

export const logGtmCalculatorFormFirstInteraction = (
  car: CarResponse,
  locale: string,
  loanData: LoanCalculatorFormValues
) => {
  return logGtmEvent({
    eventName: 'calculator_interaction',
    parameters: {
      car_data: getCarDataLayer(car),
      page_data: getPageDataLayer(locale, { page_type: 'product' }),
      form_data: {
        type: 'calculator'
      },
      financing_data: getFinancingDataLayer(loanData)
    }
  })
}

export const logGtmLoanQuoteCTAClick = (
  locale: string,
  loanData: LoanCalculatorFormValues
) => {
  return logGtmEvent({
    eventName: 'click',
    parameters: {
      user_data: getUserDataLayer(),
      page_data: getPageDataLayer(locale, { page_type: 'product' }),
      click_data: {
        type: 'cta',
        position: 'financial_calculator',
        cta_type: 'empty_button',
        cta_text: 'get_a_quote'
      },
      financing_data: getFinancingDataLayer(loanData)
    }
  })
}

export const logGtmLoanQuoteSubmission = (
  formData: Omit<InquiryFormValues, 'customerType'>,
  locale: string,
  loanData: LoanCalculatorFormValues
) => {
  return logGtmEvent({
    eventName: 'generate_lead',
    parameters: {
      user_data: getUserDataLayer(),
      page_data: getPageDataLayer(locale, { page_type: 'product' }),
      form_data: {
        type: 'financing_request',
        email: formData.email,
        phone: formData.phoneNumber,
        name: formData.firstname,
        surname: formData.surname
      },
      financing_data: getFinancingDataLayer(loanData)
    }
  })
}

export const logGtmPriceRangeContactSubmit = (
  priceRangeData: {
    priceFrom: number
    priceTo: number
  },
  carData?: OpportunityCar
) => {
  return logGtmEvent({
    eventName: 'box_below_pricing_range',
    parameters: {
      car_data: getOpportunityCarDataLayer(carData),
      price_range_data: {
        price_from: Math.floor(priceRangeData.priceFrom),
        price_to: Math.floor(priceRangeData.priceTo)
      }
    }
  })
}

interface PWProps {
  step: GTMPurchaseWizardStep
  parameters: Partial<DataLayer>
}
export const logGtmPurchaseWizard = ({ step, parameters }: PWProps) => {
  return logGtmEvent({
    eventName: 'purchase_wizard',
    parameters: {
      step,
      ...parameters
    }
  })
}
