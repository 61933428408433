import { v4 as uuid } from 'uuid'
import { StateResultsProvided } from 'react-instantsearch-core'
import { AlgoliaStockCarResponse } from 'sections/serp/Serp.types'
import { Keyword } from 'sections/serp/SerpKeywords'

export type SearchResult = StateResultsProvided<AlgoliaStockCarResponse>['searchResults'] & {
  queryID?: string
}

export const getSearchDataLayer = (
  searchResult: SearchResult,
  keywords: string | Keyword[]
): SearchDataLayer => {
  return {
    search_term:
      typeof keywords === 'string'
        ? keywords
        : keywords
            .map(k =>
              k.type !== 'attributeForMyQuery'
                ? `${k.attribute}=${k.label}`
                : k.label
            )
            .join('|'),
    search_results_returned: searchResult.nbHits,
    page: searchResult.page,
    search_id: searchResult?.queryID || uuid()
  }
}
