import { hasDataLayer } from 'driverama-core/analytics/utils'
import { GTMEvent, GTMEventName } from 'utils/gtm/event/eventTypes'
import { ExtractEventParameters } from 'driverama-core/analytics/types'
import { isProduction } from 'utils/isEnvironment'

export interface LogGTMEventProps {
  eventName: GTMEventName
  parameters: ExtractEventParameters<GTMEventName, GTMEvent>
}

export function logGtmEvent<EventName extends GTMEventName>({
  eventName,
  parameters
}: LogGTMEventProps) {
  if (!hasDataLayer()) return

  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.group(
      `%c GTM Event ${eventName}`,
      'background: black; color: white; padding: 4px;'
    )
    // eslint-disable-next-line no-console
    console.log(parameters)
    // eslint-disable-next-line no-console
    console.groupEnd()
  }

  window.dataLayer.push({
    event: eventName,
    ...parameters
  })
}
