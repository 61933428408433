import {
  hasDocument,
  hasWindow,
  localStorageClient
} from 'driverama-core/utils/dom'
import { getConsentConfigName } from 'driverama-core/analytics/events'
import { v4 as uuid } from 'uuid'

export const getUserDataLayer = (): Partial<UserDatalayer> => {
  if (!hasDocument() || !hasWindow()) return {}

  const userId = localStorageClient.getItem('userId') || undefined
  const user_type =
    (localStorageClient.getItem('userType') as UserType) || undefined

  return {
    driverama_id: userId,
    user_type,
    user_consent_id: getConsentConfigName() || undefined
  }
}

export const createLeadId = () => {
  const userId = localStorageClient.getItem('userId')

  let leadId = uuid()
  if (userId) leadId = userId
  return leadId
}
