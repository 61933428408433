import Link, { LinkProps } from 'next/link'
import { NextRouter, useRouter } from 'next/router'
import { cloneElement, isValidElement, PropsWithChildren } from 'react'

export type IsActiveFn = (
  props: LinkProps,
  router: Pick<NextRouter, 'asPath' | 'route' | 'query'>
) => boolean

type Props = {
  isActiveFn?: IsActiveFn
  disabled?: boolean
}

function getPathFromUrl(url: string) {
  return url.split(/[?#]/)[0]
}

export function ActiveLink(props: PropsWithChildren<Props> & LinkProps) {
  const { children, isActiveFn = isExactLink, disabled, ...rest } = props
  const { route, asPath, query } = useRouter()

  const active = isActiveFn && isActiveFn(rest, { route, asPath, query })

  if (isValidElement(children)) {
    return <Link {...rest}>{cloneElement(children, { active, disabled })}</Link>
  }

  return <Link {...rest}>{children}</Link>
}

export const isExactAsPathLink: IsActiveFn = (props, router) => {
  // TODO: add a case when props.href is an object
  if (typeof props.href !== 'string') {
    return false
  }

  if (getPathFromUrl(router.asPath) !== getPathFromUrl(props.href)) {
    return false
  }

  return true
}

export const isExactLink: IsActiveFn = (props, router) => {
  // TODO: add a case when props.href is an object
  if (typeof props.href !== 'string') {
    return false
  }

  if (getPathFromUrl(router.route) !== getPathFromUrl(props.href)) {
    return false
  }

  if (props.as && router.asPath !== props.as) {
    return false
  }

  return true
}

export const isSubresouceLink: IsActiveFn = (props, router) => {
  if (typeof props.href === 'string') {
    const href = getPathFromUrl(props.href)
    return router.route.startsWith(href) || router.asPath.startsWith(href)
  }

  // TODO: add a case when props.href is an object
  console.warn('UrlObject href is not supported in isSubresourceLink')
  return false
}
