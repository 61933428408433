import { LoanCalculatorFormValues } from 'components/financing/LoanCalculator.utils'
import {
  hasDocument,
  hasWindow,
  sessionStorageClient
} from 'driverama-core/utils/dom'

export const getPageDataLayer = (
  locale: string | undefined,
  page_data?: Partial<PageDataLayer>,
  section?: 'buying' | 'selling' | 'buying~wizard' | 'selling~wizard'
): Partial<PageDataLayer> => {
  if (!hasDocument() || !hasWindow()) return {}

  const prevPage = sessionStorageClient.getItem('prevPage') || undefined

  return {
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT as string,
    locale: section,
    page_location: window.location.href,
    page_language: locale || 'de',
    //'page_category': '',
    source_page: prevPage !== window.location.href ? prevPage : undefined,
    //'source_section': '',
    ...page_data
  }
}

export const getFinancingDataLayer = (
  financingData: LoanCalculatorFormValues
): Partial<FinancingLayer> => {
  const {
    loanType,
    monthlyInstallmentEurRounded,
    downPaymentValueEur,
    loanDurationMonths,
    interestRatePercentage,
    apr
  } = financingData

  return {
    loan_type: loanType,
    monthly_payment: monthlyInstallmentEurRounded,
    down_payment: downPaymentValueEur,
    loan_duration: loanDurationMonths,
    interest_rate: interestRatePercentage,
    apr
  }
}
